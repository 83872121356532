<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="input-search mb-4 col-md-5 p-0">
        <b-form-input
          autocomplete="off"
          @input="handleSearch"
          v-model="search"
          placeholder="Digite para pesquisar"
          class="mb-2"
        />
        <b-button
          variant="light"
          class="px-2 d-flex align-items-center absolute icon-action"
        >
          <b-icon icon="search"></b-icon>
        </b-button>
      </div>
      <b-button variant="secondary" @click="handleAddNewUser()" class="btn-add">
        Adicionar
      </b-button>
    </div>

    <template>
      <cards-list grid="3" gap="12" class="mb-5 pb-5 pb-md-0 mb-md-0">
        <b-card
          v-for="(item, index) in items"
          :key="index"
          :border-variant="item.id === user.id ? 'primary' : 'light'"
          class="card-hoverable pointer"
          @click="handleEditUser(item)"
        >
          <section>
            <strong class="d-block w-100">
              {{ item.name }}
            </strong>
            <small class="d-block" v-if="item.is_main">
              <b-icon icon="star" :id="item.id" /> Responsável principal
            </small>
            <small class="mt-3 d-block">
              <strong>E-mail:</strong> {{ item.email }}
            </small>
            <small v-if="item && item.enterprise" class="d-block">
              <strong>Empresa:</strong>
              {{ item.enterprise.social_reason }}
            </small>
          </section>
        </b-card>
      </cards-list>
    </template>
    <b-modal
      id="add-legal-responsible-enterprise"
      centered
      hide-footer
      size="lg"
      no-close-on-backdrop
      :title="
        `${isAdd ? 'Adicionar' : 'Editar'} responsável legal ${this.selectedUser
          .name || ''}`
      "
    >
      <Form :id="selectedId" :isAdd="isAdd" @submited="handleCloseModal" />
    </b-modal>
    <b-pagination
      v-if="total > 10"
      @change="handleChangePage"
      v-model="current_page"
      :total-rows="total"
      :per-page="per_page"
      aria-controls="my-table"
    />
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import Form from "./form";
import { mapGetters } from "vuex";

export default {
  mixins: [listPaginated],

  components: {
    Form,
  },
  data() {
    return {
      isAdd: false,
      selectedId: "",
      params: {},
      action_name: "legal_responsible",
      selectedUser: {
        id: null,
        name: null,
      },
      provider: null,
      status: 1,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    handleAddNewUser() {
      this.isAdd = true;
      this.$bvModal.show("add-legal-responsible-enterprise");
    },
    handleEditUser(user) {
      this.isAdd = false;
      this.selectedUser = user;
      this.selectedId = this.selectedUser.id;
      this.$bvModal.show("add-legal-responsible-enterprise");
    },
    resetVariables() {
      this.selectedUser = {
        id: null,
        name: null,
      };
      this.selectedId = "";
    },
    handleCloseModal() {
      this.resetVariables();
      this.$bvModal.hide("add-legal-responsible-enterprise");
      this.getList();
    },

    handleSelectprovider(provider) {
      this.$emit("open_drawer", { item: { ...provider } });
      this.provider = provider.item;
    },

    handleSetStatus(status) {
      this.status = status;
    },
  },
  created() {
    this.$root.$on("bv::modal::hidden", (bevent, id) => {
      if (id === "add-legal-responsible-enterprise") {
        this.resetVariables();
      }
    });
  },
};
</script>
